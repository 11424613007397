import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { aboutUsLinks } from 'components/Layout/navigation';
import Layout from 'components/Layout';
import TabNavigator from 'components/TabNavigator';
import { HeaderMedium } from 'components/typography';
import {
  warehousingCompanies,
  couriersCompanies,
  otherCompanies,
} from 'components/pages/Partners/companies';
import { MobileFilter } from 'components/BackgroundFilter';
import { WidthWrapper } from 'components/wrappers';
import { HeroSection } from 'components/wrappers';
import { BasicDiv } from 'components/wrappers';
import { TextDesc } from 'components/wrappers';

export const DetailContent = styled.div`
  ${HeaderMedium} {
    margin-top: 40px;
    margin-bottom: 80px;
  }
`;

export const PageContainer = styled.div`
  margin-bottom:0;
  width:100%;

  .column-whole {
    position:relative;
  }

  .column-half {
    width: 48%;
    display: inline-block;
    float: left;
    padding-right:4%;
  }

  .column-half.last {
    width: 48%;
    padding-right:0;
  }

  .column-third {
    width:31%;
    display:inline-block;
    margin-right:3%;
    float:left;
  }

  .column-third.last {
    margin-right:0;
  }

`;

export const MainSection = styled.div`
  position:relative;

  #hero-section {
    padding:0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  #hero-section .column-half {
    width: 50%;
    padding-right: 0;
    position: relative;
    padding-top: 45px;
    padding-bottom: 80px;
    padding-left: 8%;
    display: inline-block;
    float: left;
  }

  #hero-section .column-whole {
    padding: 120px 0px 135px 0px;
    max-width:650px;
    margin-left:auto;
    margin-right:auto;
  }

  #hero-section .content-container {
    position: relative;
    z-index: 2;
  }

  .hero-bg {
    position: absolute;
    top: 0;
    left:0;
    /*object-fit: cover;*/
    right:0;
    height: 100%;
    width: 100%;
    bottom: 0;
    float:left;
  }

  #partners-container {
    padding-top: 50px;
  }

  #thankyou-cta .row {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
  }

  #thankyou-cta {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  #thankyou-cta h2 {
    color: #00AF85;
    text-align: center;
    font-size: 30px;
    margin-bottom: 30px;
  }

  #thankyou-cta button {
    margin-left: auto;
    margin-right: auto;
  }

  .partners-number span {
    margin-top: 65px;
  }

  @media only screen and (max-width:1025px) { 
    #hero-section .column-whole {
      padding: 50px 0px 50px 0;
    }

    #hero-section .hero-bg {
      object-fit: cover;
    }
  }

  @media only screen and (max-width:981px) {
    #hero-section .column-half {
      width: 100%;
      padding:0;
    }

    #hero-section .column-half img{
      width: 100%;
    }

    #hero-section .column-half p{
      padding-right:0;
    }

    #hero-section .column-half.last {
      width:100%;
      padding-top:25px;
    }

    #hero-section {
      padding:40px 25px;
    }

    #hero-section h1 {
      font-size: 28px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width:875px) {

    #hero-section .column-whole {
      padding: 0px 0px 0px 0px;
      margin-left: 0;
    }
  }
`;