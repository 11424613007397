import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import Layout from 'components/Layout';
import HeroBg from 'images/local-vs-origin/local-vs-origin-bg.png';
import AllInOne from 'components/pages/Home/all-in-one';
import Partners from 'components/pages/Home/Partners';
import ArrowButton from 'components/Button/ArrowButton'

import { PageContainer, MainSection }  from 'components/pages/ThankYou';

const Clear  = styled.div`
  clear:both;
  padding:0 !important;
`;

const Row  = styled.div`
  max-width:1300px;
  margin-left:auto;
  margin-right:auto;
`;

const HeroHeader = styled.h1`
  font-size: 45px;
  margin-bottom: 10px;
  text-align:center;
  color: #ffffff;
`;

const HeroText = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  line-height:1.8;
`;
const Breadcrumbs = styled.p`
    padding: 0px 0px 25px 0px;
    text-align:center;
    color:#ffff
`;

const CallBookedPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        <title>Call Booked | Shipkoo</title>
        
        <meta name="description" content="" />
        <meta property="og:title" content="Call Booked | Shipkoo" />
        <meta property="og:description" content="" />
        <meta name="twitter:title" content="Call Booked | Shipkoo" />
        <meta name="twitter:description" content="" />
        <script>
          {`fbq('trackCustom','Schedule')`}
        </script>        
        <noscript>
          {`src="https://www.facebook.com/tr?id=474033843805879&ev=Schedule&noscript=1"`}
        </noscript>
      </Helmet>
      <PageContainer>
        <MainSection>
          <div id="hero-section" className={intl.formatMessage({ id: "language" })}>
            <img className="hero-bg" src={HeroBg} alt="fulfillment centers versus warehouse"/>
            <Row>
                <div className="column-whole">
                  <div className="content-container">
                    <Breadcrumbs className = "breadcrumb"><Link to="/">Home</Link> / Call Booked</Breadcrumbs>
                    <HeroHeader>THANK YOU <br />FOR BOOKING A CALL!</HeroHeader>
                    <HeroText>We will be in touch with you shortly</HeroText>
                  </div>
                </div>
                <Clear></Clear>
            </Row>
          </div>
          <div id="partners-container">
            <Partners />
          </div>
          <AllInOne />
          <div id="thankyou-cta">
            <div className="row">
              <h2>Try Our Order Management System for Free</h2>
              <a href="https://app.shipkoo.com/register">
                <ArrowButton>Sign up here</ArrowButton>
              </a>
            </div>
          </div>
        </MainSection>
      </PageContainer>
    </Layout>
  );
};

export default CallBookedPage;
